<template>
  <div>

    <infoHeader :content_name="'修改稿件内容'" />

    <el-form ref="form" label-width="160px" :label-position="'left'">
      <el-form-item label="故障情况">
        <el-input v-model="form.abnormal_question" placeholder="如 夹臂无法收回，驱动器报红灯，等"></el-input>
      </el-form-item>

      <el-form-item label="故障类型">
        <el-select v-model="form.types" placeholder="请选择">
          <el-option label="不区分" :value="1" ></el-option>
          <el-option label="硬件" :value="2" ></el-option>
          <el-option label="软件" :value="3" ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="故障相关类目">
        <el-select v-model="form.question_type_id" placeholder="请选择">
          <el-option
            label="未知"
            :value="0"
          >
          </el-option>
          <el-option
            v-for="item in option.question_type"
            :key="item.id"
            :label="item.type_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="除标题外相关索引词">
        <el-input v-model="form.question_suit" placeholder="可以写设备名, 设备型号, 等等"></el-input>
      </el-form-item>

      <el-divider content-position="left">更新内容说明</el-divider>

      <el-form-item label="更新或补充说明">
        <el-input v-model="form.update_illustrate" placeholder="更新或补充说明"></el-input>
      </el-form-item>

      <el-form-item label="更新或补充类型">
        <el-select v-model="form.update_type" placeholder="请选择">
          <el-option label="请选择" :value="null" ></el-option>
          <el-option label="补充" :value="1" ></el-option>
          <el-option label="修正" :value="2" ></el-option>
        </el-select>
      </el-form-item>

    </el-form>

    <el-divider content-position="left">问题解决方法</el-divider>

    <editor v-model="form.resolvent" :content='form.resolvent' uploadkey="questionlib_img"/>

    <el-form ref="form" style="margin-top: 30px">
      <el-form-item>
        <el-button type="primary" @click="submitUpdate">确认无误，立即创建</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import editor from "@/components/Editor.vue";
import { equipmentFaultManageGetinfo, equipmentFaultManageQuestionTypeQuery, equipmentFaultManageSubmitUpdate } from "@/api/manage/operation/equipment_fault_manage.js";
// @ is an alias to /src
export default {
    name: '',
    data() {
        return {
            question_id: this.$route.query.id,
            form: {
              id: this.$route.query.id,
              abnormal_question: null,
              question_type_id: 0,
              question_suit: null,
              resolvent: null,
              types: 1,
              update_illustrate: null,
              update_type: null
            },
            option: {
              question_type: []
            }
        };
    },
    components: {
      editor
    },
    created(){
      this.getInfo()
      this.getOption()
    },
    methods:{
      getOption(){
        equipmentFaultManageQuestionTypeQuery({}).then((res) => {
            this.option.question_type = res.data;
        });
      },
      getInfo(){
        equipmentFaultManageGetinfo({
            id: this.question_id
        }).then((res) => {
          this.form.abnormal_question = res.data.question.abnormal_question
          this.form.question_suit = res.data.question.question_suit
          this.form.question_type_id = res.data.question.question_type.id
          this.form.resolvent = res.data.question.resolvent
          this.form.types = res.data.question.types_int
        });
      },
      submitUpdate(){
        equipmentFaultManageSubmitUpdate(this.form).then(() => {
          this.$router.go(-1)
        });
      },
    }
}
</script>
<style lang="scss" scoped></style>
